.dragPopup {
  max-width: 100vw;
  min-width: 500px;
  width: auto;
  position: fixed;
  margin-left: -250px;
  margin-top: 50px;
  z-index: 9999;
  top: 50px;
  left: 50%;
  background-color: white;
  border: 1px solid #777;
  border-radius: 4px;
  box-shadow: 2px 2px 5px #333;
  .header {
    font-family: 'Segoe UI', 'Source Sans Pro', Calibri, Candara, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.1;
    color: inherit;
    font-size: 26px;
    padding: 15px;
  }
  .closeButton {
    float: right;
    padding: 2px;
    font-size: 18px;
    cursor: pointer;
  }
  .closeButton:hover {
    color: darkred;
  }
  .dragContainer {
    .dragContent {
      width: 100%;
      padding: 10px;
      max-height: 85vh;
      overflow: auto;
    }
  }
}
