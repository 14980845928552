.operations-board {
  $vin-blue: #161855;
  padding: 10px;
  .operations-board-header {
    font-size: 1.5em;
    margin-bottom: 15px;
    padding: 0;
    display: flex;
    justify-content: center;
    border: 1px solid #838384;
    padding: 20px;
    .navigation {
      display: flex;
      justify-content: space-between;
      width: 480px;
      max-width: 480px;

      .dayLabel {
        // color: white;
        display: flex;
        font-style: normal;
        font-weight: 800;
        font-size: 16px !important;
        line-height: 14px;
        text-align: center;
        text-transform: capitalize;
      }
      .alert-wrapper {
        flex-wrap: nowrap;
        flex-direction: row-reverse;
        align-items: center;
        align-content: center;
        margin-left: 5px;
        margin-top: -2px;
        width: 0;
        max-width: 50px;
        text-align: right;
      }
      .alert-icon {
        color: #ffae42;
      }

      .navOpt {
        cursor: pointer;
        color: white;
        font-weight: 400;
        font-size: 16px !important;
        line-height: 14px;
      }
      .navOpt:hover {
        text-decoration: underline;
        color: #9d5949;
      }
    }
  }
  .operations-board-body {
    display: flex;
    .column {
      display: flex;
      flex-wrap: wrap;
      vertical-align: top;
      min-width: 300px;
      margin-right: 10px;
      width: 100%;
      margin: 0px;
      @media (min-width: 1540px) {
        .card-width {
          width: 310px;
        }
        .card-width.narrow {
          width: unset;
        }
      }

      .container-wrapper.narrow {
        min-width: 200px;
      }
      .container-wrapper {
        background: #fff;
        border-radius: 5px 5px 2px 2px;
        margin-bottom: 36px;
        min-width: 190px;
        border: 2px solid #3c3c3c;

        .border {
          border: 2px solid #3c3c3c;
        }
        .container-header {
          color: #fff;
          font-size: 1.2em;
          font-weight: bold;
          min-height: 34px;
          padding: 5px;
          text-align: center;
          .header-icon {
            display: inline-block;
            float: left;
          }
        }

        .bg-container-header {
          background: #bd6b58;
        }

        .bg-container-header-netduty {
          background: #161855;
        }

        .bg-container-body-netduty {
          background: #fff;
        }

        .narrow {
          max-width: 200px;
          white-space: break-spaces;
        }

        .container-body {
          padding: 5px;
          .dropzone-wrapper {
            .dropzone-header {
              .netduty {
                color: black;
              }
              font-size: 1.1em;
              padding: 5px 0;
            }
            .dropzone-body {
              .draggable-element {
                min-height: 40px;
                margin: 5px 0;
                word-break: auto;
                white-space: pre-wrap;
                white-space: -moz-pre-wrap;
                word-wrap: break-word;
                overflow-wrap: break-word;
                overflow: visible;
                .title {
                  line-height: 20px;
                  font-weight: bold;
                }
                .details {
                  padding: 0 10px;
                  margin-bottom: 0;
                  color: inherit;
                  background-color: inherit;
                  border: none;
                  font-family: inherit;
                  font-size: inherit;
                  word-break: auto;
                  white-space: pre-wrap;
                  white-space: -moz-pre-wrap;
                  word-wrap: break-word;
                  overflow-wrap: break-word;
                  overflow: visible;
                }
              }
            }
          }
        }
          .scroll-y {
            max-height: 90%;
            overflow-y: auto;    
          }
      }
    }
  }

  .compact-zones {
    .dropzone-wrapper {
      margin-bottom: 0 !important;
    }
    .draggable-element div {
      border: none !important;
    }
    div {
      min-height: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .dropzone-body {
      min-height: 0 !important;
      .draggable-element {
        min-height: 0 !important;
        div {
          border: none;
        }
      }
    }
  }
}
